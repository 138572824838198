import React from 'react';

import { UniversalLink } from '@plone/volto/components';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';

import { Container } from 'design-react-kit/dist/design-react-kit';

import { useState, useEffect } from 'react';

const IconsList = (props) => {
  const { data } = props;
  const [iconValue, setIconValue] = useState('');
  const [icon2Value, setIcon2Value] = useState('');
  const [icon3Value, setIcon3Value] = useState('');

  useEffect(() => {
    setIconValue(data.icon);
  }, [data.icon]);

  useEffect(() => {
    setIcon2Value(data.icon2);
  }, [data.icon2]);

  useEffect(() => {
    setIcon3Value(data.icon3);
  }, [data.icon3]);

  return (
    <div className="contact-subsection-content icon-list-subsection">
      <Container className="list-container">
        <div className="list-item">
          <div className="icon-section">
            {iconValue === data.icon && <Icon icon={iconValue} />}
          </div>
          <div className="content-section">
            <div className="text-section">
              <p>{data.text}</p>
            </div>
            {data.link?.length > 0 && (
              <div className="link-section">
                <UniversalLink href={data.link[0]?.['@id']}>
                  {' '}
                  <u>{data.linktext}</u>
                  {data.linktext && (
                    <Icon
                      className="arrow-icon"
                      icon="it-arrow-right"
                      color="white"
                    />
                  )}
                </UniversalLink>
              </div>
            )}
          </div>
        </div>

        <div className="list-item">
          <div className="icon-section">
            {icon2Value === data.icon2 && <Icon icon={icon2Value} />}
          </div>
          <div className="content-section">
            <div className="text-section">
              <p>{data.text2}</p>
            </div>
            {data.link2?.length > 0 && (
              <div className="link-section">
                <UniversalLink href={data.link2[0]?.['@id']}>
                  {' '}
                  <u>{data.linktext2}</u>
                  {data.linktext2 && (
                    <Icon
                      className="arrow-icon"
                      icon="it-arrow-right"
                      color="white"
                    />
                  )}
                </UniversalLink>
              </div>
            )}
          </div>
        </div>

        <div className="list-item">
          <div className="icon-section">
            {icon3Value === data.icon3 && <Icon icon={icon3Value} />}
          </div>
          <div className="content-section">
            <div className="text-section">
              <p>{data.text3}</p>
            </div>
            {data.link3?.length > 0 && (
              <div className="link-section">
                <UniversalLink href={data.link3[0]?.['@id']}>
                  {' '}
                  <u>{data.linktext3}</u>
                  {data.linktext3 && (
                    <Icon
                      className="arrow-icon"
                      icon="it-arrow-right"
                      color="white"
                    />
                  )}
                </UniversalLink>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default IconsList;
