import React from 'react';

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';

import {
  searchContent,
  resetSearchContent,
  getVocabulary,
} from '@plone/volto/actions';

import { Container, Button } from 'design-react-kit/dist/design-react-kit';

import { TextFilter } from 'design-comuni-plone-theme/components/ItaliaTheme/Blocks/Common/SearchFilters';

import { SelectInput } from 'design-comuni-plone-theme/components';

import ResultTable from './Blocks/ResultTable';

import MessageContainer from '../../MessageContainer/MessageContainer';
import TablePagination from '../../TablePagination/TablePagination';

const Body = ({ data, inEditMode, path, onChangeBlock, properties }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const tipiDocumento = useSelector(
    (state) =>
      state.vocabularies['ospedaligalliera.policy.DocumentoQualitaCategories']
        ?.items ?? [],
  );

  const area_interesse_vocabulary = useSelector(
    (state) =>
      state.vocabularies['ospedaligalliera.policy.DocumentoQualitaInterestArea']
        ?.items ?? [],
  );

  const utile_per_vocabulary = useSelector(
    (state) =>
      state.vocabularies['ospedaligalliera.policy.DocumentoQualitaUsefulfor']
        ?.items ?? [],
  );

  const strutture = useSelector(
    (state) =>
      state.vocabularies['ospedaligalliera.policy.DocumentoQualitaStructures']
        ?.items ?? [],
  );

  const [searchField, setSearchField] = useState('');
  const [selectStruttura, setSelectStruttura] = useState('');
  const [selectTipoDocumento, setSelectTipoDocumento] = useState('');
  const [filters, setFilters] = useState({
    search: '',
    struttura: '',
    tipoDocumento: '',
    areaInteresse: '',
    utilePer: '',
  });

  const searchResults = useSelector((state) => state.search.items);
  const totResults = useSelector((state) => state.search.total);

  // Redux - loading
  const isResultLoading = useSelector((state) => state.search.loading);

  // Redux - error

  const error = useSelector((state) => state.search.error);
  const noError = error === null;

  //** funzioni test campi vuoti */
  const [selectAreaInteresse, setSelectAreaInteresse] = useState('');
  const [selectUtilePer, setSelectUtilePer] = useState('');

  const onChangeSearch = (e, data) => {
    setSearchField(data);
  };

  const filterOutStruttura = (filter) => {
    setSelectStruttura(filter);
    if (filter === null) {
      setFilters({ ...filters, struttura: '' });
    }
  };

  const filterOutTipoDocumento = (filter) => {
    setSelectTipoDocumento(filter);
    if (filter === null) {
      setFilters({ ...filters, tipoDocumento: '' });
    }
  };

  //** funzioni test campi vuoti  - da finire */

  const filterOutAreaInteresse = (filter) => {
    setSelectAreaInteresse(filter);
    if (filter === null) {
      setFilters({ ...filters, areaInteresse: '' });
    }
  };

  const filterOutUtilePer = (filter) => {
    setSelectUtilePer(filter);
    if (filter === null) {
      setFilters({ ...filters, utilePer: '' });
    }
  };

  //** funzione applica filtri */

  const applyFilters = () => {
    setFilters({
      ...filters,
      search: searchField,
      struttura: selectStruttura,
      tipoDocumento: selectTipoDocumento,
      areaInteresse: selectAreaInteresse,
      utilePer: selectUtilePer,
    });
    setCurrentPage(1);
  };

  const noFiltersSet =
    filters.search === '' &&
    filters.struttura === '' &&
    filters.tipoDocumento === '' &&
    filters.utilePer === '' &&
    filters.areaInteresse === '';

  //* Pagination **/

  const [currentPage, setCurrentPage] = useState(1);
  const bSize = 20;
  const bStart = (currentPage - 1) * bSize;

  //* Calcolo numero pagine

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totResults / bSize); i++) {
    pageNumbers.push(i);
  }

  const lastPage = pageNumbers.length;

  const lastResIndex = bSize * currentPage;

  const firstResIndex = bSize * (currentPage - 1) + 1;

  //* Hook useEffect per parametri ricerca documenti */

  useEffect(() => {
    dispatch(
      searchContent(data.searchOnSite ? '' : path, {
        'path.depth': 10,
        metadata_fields: [
          'structures',
          'categories',
          'startDate',
          'revision',
          'useful_for',
          'interest_area',
        ],
        SearchableText: filters.search,
        structures: filters.struttura?.value,
        categories: filters.tipoDocumento?.value,
        useful_for: filters.utilePer?.value,
        interest_area: filters.areaInteresse?.value,

        portal_type: 'DocumentoQualita',

        b_size: bSize,
        b_start: bStart,
        sort_on: 'sortable_title',
        sort_order: 'ascending',
      }),
    );
    return () => {
      dispatch(resetSearchContent());
    };
  }, [dispatch, path, data.searchOnSite, filters, bSize, bStart]);

  useEffect(() => {
    dispatch(
      getVocabulary({
        vocabNameOrURL: 'ospedaligalliera.policy.DocumentoQualitaUsefulfor',
      }),
    );
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      getVocabulary({
        vocabNameOrURL: 'ospedaligalliera.policy.DocumentoQualitaInterestArea',
      }),
    );
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      getVocabulary({
        vocabNameOrURL: 'ospedaligalliera.policy.DocumentoQualitaStructures',
      }),
    );
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      getVocabulary({
        vocabNameOrURL: 'ospedaligalliera.policy.DocumentoQualitaCategories',
      }),
    );
  }, [dispatch]);

  return (
    <Container>
      <div className="rounded container">
        <div className="d-flex justify-content-center">
          <div className="d-flex search-container align-items-center justify-content-center flex-wrap">
            <form
              className="search-form"
              onSubmit={(event) => {
                event.preventDefault();
                applyFilters();
              }}
            >
              <div className="search-box-container">
                <TextFilter
                  value={searchField}
                  onChange={(e, data) => {
                    onChangeSearch(e, data);
                  }}
                  placeholder={intl.formatMessage(
                    messages.textFilterPlaceholder,
                  )}
                  type="submit"
                />{' '}
              </div>

              <div className="filters-line">
                <div className="mr-lg-3 my-2 my-lg-1 filter-wrapper select-filter">
                  <SelectInput
                    id="struttura"
                    value={selectStruttura}
                    placeholder={intl.formatMessage(
                      messages.selectStrutturaPlaceholder,
                    )}
                    onChange={(opt) => {
                      filterOutStruttura(opt);
                    }}
                    options={strutture}
                    isClearable={true}
                  />
                </div>
                <div className="mr-lg-3 my-2 my-lg-1 filter-wrapper select-filter">
                  <SelectInput
                    id="tipo-documento"
                    value={selectTipoDocumento}
                    placeholder={intl.formatMessage(
                      messages.selectTipoDocumentoPlaceholder,
                    )}
                    onChange={(opt) => {
                      filterOutTipoDocumento(opt);
                    }}
                    options={tipiDocumento}
                    isClearable={true}
                  />
                </div>

                {/** Campi select prova **/}
                <div className="mr-lg-3 my-2 my-lg-1 filter-wrapper select-filter">
                  <SelectInput
                    id="area-interesse"
                    value={selectAreaInteresse}
                    placeholder={intl.formatMessage(
                      messages.selectAreaPlaceholder,
                    )}
                    onChange={(opt) => {
                      filterOutAreaInteresse(opt);
                    }}
                    options={area_interesse_vocabulary}
                    isClearable={true}
                  />
                </div>
                <div className="mr-lg-3 my-2 my-lg-1 filter-wrapper select-filter">
                  <SelectInput
                    id="utile-per"
                    value={selectUtilePer}
                    placeholder={intl.formatMessage(
                      messages.selectUtilePerPlaceholder,
                    )}
                    onChange={(opt) => {
                      filterOutUtilePer(opt);
                    }}
                    options={utile_per_vocabulary}
                    isClearable={true}
                  />
                </div>
                <Button
                  color={'tertiary'}
                  icon={false}
                  tag="button"
                  className="apply-filters-button"
                  type="submit"
                >
                  {intl.formatMessage(messages.searchButton)}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="result-table-section">
        <div className="result-table-container">
          {searchResults.length > 0 && (
            <ResultTable tableData={searchResults} messages={messages} />
          )}
        </div>
        {searchResults.length === 0 && noError && !isResultLoading && (
          <MessageContainer messageType="no-res" />
        )}

        {/* Mostra durante caricamento */}
        {isResultLoading && <MessageContainer messageType="loading" />}

        {/* Mostra quando si verifica un errore */}
        {!noError && <MessageContainer messageType="error" />}
        <TablePagination
          searchResults={searchResults}
          firstResIndex={firstResIndex}
          lastResIndex={lastResIndex}
          totResults={totResults}
          noFiltersSet={noFiltersSet}
          pageNumbers={pageNumbers}
          currentPage={currentPage}
          lastPage={lastPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </Container>
  );
};

export default Body;

const messages = defineMessages({
  textFilterPlaceholder: {
    id: 'textFilterPlaceholder',
    defaultMessage: 'Digita un nome o una parola chiave',
  },
  selectStrutturaPlaceholder: {
    id: 'selectStrutturaPlaceholder',
    defaultMessage: 'Struttura',
  },
  selectTipoDocumentoPlaceholder: {
    id: 'selectTipoDocumentoPlaceholder',
    defaultMessage: 'Tipo documento',
  },
  selectAreaPlaceholder: {
    id: 'selectAreaPlaceholder',
    defaultMessage: "Area d'interesse",
  },
  selectUtilePerPlaceholder: {
    id: 'selectUtilePerPlaceholder',
    defaultMessage: 'Utile per',
  },
  searchButton: {
    id: 'searchButton',
    defaultMessage: 'Cerca',
  },
  tabellaHeaderStrutture: {
    id: 'tabellaHeaderStrutture',
    defaultMessage: 'Strutture',
  },
  tabellaHeaderTipo: {
    id: 'tabellaHeaderTipo',
    defaultMessage: 'Tipo',
  },
  tabellaHeaderTitoloDoc: {
    id: 'tabellaHeaderTitoloDoc',
    defaultMessage: 'Titolo documento',
  },
  tabellaHeaderDescrizioneDoc: {
    id: 'tabellaHeaderDescrizioneDoc',
    defaultMessage: 'Descrizione',
  },
  tabellaHeaderRevisione: {
    id: 'tabellaHeaderRevisione',
    defaultMessage: 'Revisione',
  },
  tabellaHeaderData: {
    id: 'tabellaHeaderData',
    defaultMessage: 'Data',
  },
  tabellaHeaderAzione: {
    id: 'tabellaHeaderAzione',
    defaultMessage: 'Azione',
  },
  downloadButton: {
    id: 'downloadButton',
    defaultMessage: 'Scarica',
  },
});
