// CUSTOMIZATION
// - Aggiunto tasto rubrica

/**
 * HeaderSlim component.
 * @module components/ItaliaTheme/Header/HeaderSlim
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';

import { UniversalLink } from '@plone/volto/components';
import {
  ParentSiteMenu,
  LanguageSelector,
  ArLogin,
  TertiaryMenu,
  Icon,
} from 'design-comuni-plone-theme/components/ItaliaTheme';

const messages = defineMessages({
  rubrica: {
    id: 'rubrica',
    defaultMessage: 'Rubrica',
  },
});

const HeaderSlimRightZone = () => {
  const intl = useIntl();
  const subsite = useSelector((state) => state.subsite?.data);
  return (
    <>
      <ParentSiteMenu />
      {!subsite && <TertiaryMenu />}
      <LanguageSelector />
      <div className="rubrica-link-section">
        <UniversalLink href="/rubrica-telefonica">
          <Icon className="contact-icon" icon="phone-alt" />
          {intl.formatMessage(messages.rubrica)}
        </UniversalLink>
      </div>
      <ArLogin />
    </>
  );
};

export default HeaderSlimRightZone;
