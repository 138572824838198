import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';
import { RichTextArticle } from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const PersonaReparto = ({ content }) => {
  const intl = useIntl();
  const reparti = content?.division;

  const showSection = content?.division?.length > 0;

  return showSection ? (
    <RichTextArticle
      tag_id="department"
      title={intl.formatMessage(messages.division)}
    >
      {reparti?.map((rep) => {
        return (
          <div className="mb-5">
            <UniversalLink href={rep['@id']}>{rep.title}</UniversalLink>
          </div>
        );
      })}
    </RichTextArticle>
  ) : (
    <></>
  );
};
export default PersonaReparto;

const messages = defineMessages({
  division: {
    id: 'division',
    defaultMessage: 'Reparto',
  },
});
