import React from 'react';
import { UniversalLink } from '@plone/volto/components';

import {
  richTextHasContent,
  RichText,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';

const DepartmentInfo = ({ headerInfo, departmentInfo }) => {
  return richTextHasContent(headerInfo) || departmentInfo?.length > 0 ? (
    <div className="header-infos px-4 mb-5">
      {richTextHasContent(headerInfo) && (
        <RichText serif={false} content={headerInfo} />
      )}
      {departmentInfo?.length > 0 &&
        departmentInfo.map((depInfo) => {
          return (
            <div className="department-link">
              <UniversalLink href={depInfo['@id']}>
                {depInfo.title} <Icon icon="it-arrow-right" />
              </UniversalLink>
            </div>
          );
        })}
    </div>
  ) : (
    <></>
  );
};

export default DepartmentInfo;
