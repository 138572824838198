import React from 'react';

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  searchContent,
  resetSearchContent,
  getVocabulary,
} from '@plone/volto/actions';
import { readAsDataURL } from 'promise-file-reader';

import { defineMessages, useIntl } from 'react-intl';

import {
  Container,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Alert,
} from 'design-react-kit/dist/design-react-kit';
import { UniversalLink } from '@plone/volto/components';
import { TextFilter } from 'design-comuni-plone-theme/components/ItaliaTheme/Blocks/Common/SearchFilters';
import { SelectInput } from 'design-comuni-plone-theme/components';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';

import ResultTable from './Blocks/ResultTable';
import Navbar from './Blocks/Navbar';

import MessageContainer from '../../MessageContainer/MessageContainer';
import TablePagination from '../../TablePagination/TablePagination';

import { loadExcel } from '../../../actions/loadExcel/loadExcel';
import { resetState } from '../../../actions/resetState/resetState';

//*lettere per navbar
const alphabet = [
  { id: 1, letter: 'a' },
  { id: 2, letter: 'b' },
  { id: 3, letter: 'c' },
  { id: 4, letter: 'd' },
  { id: 5, letter: 'e' },
  { id: 6, letter: 'f' },
  { id: 7, letter: 'g' },
  { id: 8, letter: 'h' },
  { id: 9, letter: 'i' },
  { id: 10, letter: 'l' },
  { id: 11, letter: 'm' },
  { id: 12, letter: 'n' },
  { id: 13, letter: 'o' },
  { id: 14, letter: 'p' },
  { id: 15, letter: 'q' },
  { id: 16, letter: 'r' },
  { id: 17, letter: 's' },
  { id: 18, letter: 't' },
  { id: 19, letter: 'u' },
  { id: 20, letter: 'v' },
  { id: 21, letter: 'z' },
];

const Body = (props) => {
  const { path, properties } = props;
  const intl = useIntl();
  //* utils
  const dispatch = useDispatch();
  const link = properties['@id'];

  // vocabolario per Libri

  const bookYears = useSelector(
    (state) =>
      state.vocabularies['ospedaligalliera.policy.BookYears']?.items ?? [],
  );
  const bookEditors = useSelector(
    (state) =>
      state.vocabularies['ospedaligalliera.policy.BookEditors']?.items ?? [],
  );

  useEffect(() => {
    dispatch(
      getVocabulary({
        vocabNameOrURL: 'ospedaligalliera.policy.BookYears',
        size: 1000,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getVocabulary({
        vocabNameOrURL: 'ospedaligalliera.policy.BookEditors',
        size: 1000,
      }),
    );
  }, [dispatch]);

  //* stato filtri
  const [searchField, setSearchField] = useState('');
  const [selectEditore, setSelectEditore] = useState('');
  const [selectAnno, setSelectAnno] = useState('');
  const [filterLetter, setFilterLetter] = useState('');
  const [filters, setFilters] = useState({
    search: '',
    editore: '',
    anno: '',
  });

  const onChangeSearch = (e, data) => {
    setSearchField(data);
  };

  const filterEditore = (filter) => {
    setSelectEditore(filter);
    if (filter === null) {
      setFilters({ ...filters, editore: '' });
    }
  };

  const filterAnno = (filter) => {
    setSelectAnno(filter);
    if (filter === null) {
      setFilters({ ...filters, anno: '' });
    }
  };

  const onClickFilter = (letter) => {
    const selectedLetter = letter;
    setFilterLetter(selectedLetter);
    setCurrentPage(1);
  };

  //** risultati ricerca */

  const searchResults = useSelector((state) => state.search.items);
  const totResults = useSelector((state) => state.search.total);

  // Redux - loading
  const isResultLoading = useSelector((state) => state.search.loading);

  // Redux - error

  const error = useSelector((state) => state.search.error);
  const noError = error === null;

  //** funzione applica filtri */

  const applyFilters = () => {
    setFilters({
      ...filters,
      search: searchField,
      editore: selectEditore,
      anno: selectAnno,
    });
    setCurrentPage(1);
  };

  const resetLetterFilter = () => {
    setFilterLetter('');
  };

  const noFiltersSet =
    filters.search === '' &&
    filters.editore === '' &&
    filters.anno === '' &&
    filterLetter === '';

  //* Pagination **/

  const [currentPage, setCurrentPage] = useState(1);
  const bSize = 15;
  const bStart = (currentPage - 1) * bSize;

  //* Calcolo numero pagine

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totResults / bSize); i++) {
    pageNumbers.push(i);
  }

  const lastPage = pageNumbers.length;

  const lastResIndex = bSize * currentPage;

  const firstResIndex = bSize * (currentPage - 1) + 1;

  // Hook useSelector per accedere a store per ruolo utenti

  const userRole = useSelector((state) => state.users.user.roles);

  const addContentRole =
    userRole?.includes('Contributor') || userRole?.includes('Manager');

  // upload excel file
  const [isOpen, toggleModal] = useState(false);
  const [excelData, setExcelData] = useState('');
  const [loadedFile, setLoadedFile] = useState('');

  const onUpload = ({ target }) => {
    const file = target.files[0];
    setLoadedFile(file.name);

    readAsDataURL(file).then((data) => {
      const fields = data.match(/^data:(.*);(.*),(.*)$/);
      const fileData = fields[3];
      setExcelData(fileData);
    });
  };

  // manage state of upload excel file
  const loadExcelError = useSelector((state) => state.loadExcel.error);
  const loadExcelLoading = useSelector((state) => state.loadExcel.loading);
  const loadExcelLoaded = useSelector((state) => state.loadExcel.loaded);
  const loadExcelMessage = useSelector((state) => state.loadExcel.message);

  const loadExcelOnClick = () => {
    dispatch(loadExcel(path, excelData, '@upload-books'));
  };

  const resetUpload = () => {
    dispatch(resetState());
  };

  useEffect(() => {
    return () => dispatch(resetState());
  }, [dispatch]);

  useEffect(() => {
    toggleModal(false);
    setLoadedFile('');
  }, [loadExcelLoaded]);

  //* Hook useEffect per parametri ricerca libri

  useEffect(() => {
    dispatch(
      searchContent(path, {
        'path.depth': 1,
        metadata_fields: ['anno', 'autore', 'luogo', 'sottotitolo'],
        SearchableText: filters.search,
        luogo: filters.editore?.label,
        anno: filters.anno?.label,
        portal_type: 'Libro',
        startswith: filterLetter,

        b_size: bSize,
        b_start: bStart,
        sort_on: 'sortable_title',
        sort_order: 'ascending',
      }),
    );
    return () => {
      dispatch(resetSearchContent());
    };
  }, [dispatch, path, filters, filterLetter, bSize, bStart, loadExcelLoaded]);

  return (
    <Container>
      <div className="rounded container mb-5">
        <div className="d-flex justify-content-center">
          <div className="d-flex search-container align-items-center justify-content-center">
            <form
              className="search-form"
              onSubmit={(event) => {
                event.preventDefault();
                if (noFiltersSet) {
                  applyFilters();
                } else {
                  setFilters({
                    ...filters,
                    search: searchField,
                    editore: selectEditore,
                    anno: selectAnno,
                  });
                }
              }}
            >
              <div className="search-box-container">
                <TextFilter
                  value={searchField}
                  onChange={(e, data) => {
                    onChangeSearch(e, data);
                  }}
                  placeholder={intl.formatMessage(
                    messages.textFilterPlaceholder,
                  )}
                  type="submit"
                />{' '}
              </div>

              <div className="filters-line">
                <div className="mr-lg-3 my-2 my-lg-1 filter-wrapper select-filter">
                  <SelectInput
                    id="editore"
                    value={selectEditore}
                    placeholder={intl.formatMessage(
                      messages.selectEditorePlaceholder,
                    )}
                    onChange={(opt) => {
                      filterEditore(opt);
                    }}
                    options={bookEditors}
                    isClearable={true}
                  />
                </div>
                <div className="mr-lg-3 my-2 my-lg-1 filter-wrapper select-filter">
                  <SelectInput
                    id="anno-pubblicazione"
                    value={selectAnno}
                    placeholder={intl.formatMessage(
                      messages.selectAnnoPlaceholder,
                    )}
                    onChange={(opt) => {
                      filterAnno(opt);
                    }}
                    options={bookYears}
                    isClearable={true}
                  />
                </div>

                <Button
                  color={'tertiary'}
                  icon={false}
                  tag="button"
                  className="apply-filters-button"
                  type="submit"
                >
                  {intl.formatMessage(messages.searchButton)}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="rounded container file-manager-container">
        <div className="d-flex">
          {addContentRole && (
            <div className="add-file-container">
              <div className="add-file-item">
                <div className="icon-container">
                  <Icon icon="file-upload" />
                </div>
                <div className="link-container">
                  <UniversalLink href={`${link}/add?type=Libro`}>
                    {intl.formatMessage(messages.addButton)}
                  </UniversalLink>
                </div>
              </div>
              <div className="add-file-item">
                <div className="icon-container">
                  <Icon icon="file-excel" />
                </div>
                <div className="link-container">
                  <a
                    href={`/${path}`}
                    onClick={(event) => {
                      event.preventDefault();
                      toggleModal(!isOpen);
                    }}
                  >
                    {intl.formatMessage(messages.importFromExcel)}
                  </a>
                </div>
              </div>
            </div>
          )}
          <div className="add-file-item">
            <div className="icon-container">
              <Icon icon="file-download" />
            </div>
            <div className="link-container">
              <a href={`${path}/@@download/export-books`}>
                {intl.formatMessage(messages.exportExcel)}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="navbar-section">
        <div className="navbar-section-title">
          <h3>{intl.formatMessage(messages.elencoTesti)}</h3>
        </div>
        <div className="testi-navbar-container">
          <Navbar
            alphabet={alphabet}
            onClickHandler={onClickFilter}
            filterLetter={filterLetter}
            resetLetterFilter={resetLetterFilter}
          />
        </div>
      </div>

      <div className="result-table-section">
        {loadExcelLoaded && (
          <Alert color="success">
            {intl.formatMessage(messages.importSuccessful)}. {loadExcelMessage}
          </Alert>
        )}
        <div className="result-table-container">
          <ResultTable
            tableData={searchResults}
            messages={messages}
            addContentRole={addContentRole}
          />
        </div>
        {searchResults.length === 0 && noError && !isResultLoading && (
          <MessageContainer messageType="no-res" />
        )}
        {/* Mostra durante caricamento */}
        {isResultLoading && <MessageContainer messageType="loading" />}

        {/* Mostra quando si verifica un errore */}
        {!noError && <MessageContainer messageType="error" />}
        <TablePagination
          searchResults={searchResults}
          firstResIndex={firstResIndex}
          lastResIndex={lastResIndex}
          totResults={totResults}
          noFiltersSet={noFiltersSet}
          pageNumbers={pageNumbers}
          currentPage={currentPage}
          lastPage={lastPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <div>
        <Modal
          isOpen={isOpen}
          toggle={() => toggleModal(!isOpen)}
          className="import-excel-modal"
        >
          <ModalHeader toggle={() => toggleModal(!isOpen)} id="esempio2">
            {intl.formatMessage(messages.importFromExcel)}
          </ModalHeader>
          <ModalBody>
            {!loadExcelError && !loadExcelLoading && (
              <div className="excel-modal-content">
                <div className="file-loader">
                  <label for="upload-file" className="ui btn button file">
                    {intl.formatMessage(messages.selectExcelFile)}
                    <input
                      id="upload-file"
                      type="file"
                      onChange={onUpload}
                      style={{ visibility: 'hidden' }}
                      accept=".xls,.xlsx"
                    />
                  </label>
                </div>
                {loadedFile !== '' && (
                  <div className="info-container">
                    <p>
                      <strong>
                        {intl.formatMessage(messages.selectedFile)}
                      </strong>{' '}
                      {loadedFile}
                    </p>
                  </div>
                )}
              </div>
            )}
            {loadExcelError && !loadExcelLoading && (
              <div>{intl.formatMessage(messages.uploadError)}</div>
            )}
            {loadExcelLoading && !loadExcelError && (
              <div style={{ textAlign: 'center' }} className="mb-3">
                {intl.formatMessage(messages.uploadLoading)}
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                if (loadExcelError != null) {
                  resetUpload();
                  toggleModal(!isOpen);
                } else {
                  toggleModal(!isOpen);
                }
              }}
            >
              Chiudi
            </Button>
            {loadExcelError === null && (
              <Button color="primary" onClick={() => loadExcelOnClick()}>
                Carica file
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </div>
    </Container>
  );
};

export default Body;

const messages = defineMessages({
  textFilterPlaceholder: {
    id: 'textFilterPlaceholder',
    defaultMessage: 'Digita un nome o una parola chiave',
  },
  selectEditorePlaceholder: {
    id: 'selectEditorPlaceholder',
    defaultMessage: 'Editore',
  },
  selectAnnoPlaceholder: {
    id: 'selectAnnoPlaceholder',
    defaultMessage: 'Anno di pubblicazione',
  },
  searchButton: {
    id: 'searchButton',
    defaultMessage: 'Cerca',
  },
  addButton: {
    id: 'addButton',
    defaultMessage: 'Aggiungi un libro alla biblioteca',
  },
  importFromExcel: {
    id: 'importFromExcel',
    defaultMessage: 'Importa da Excel',
  },
  exportExcel: {
    id: 'exportExcel',
    defaultMessage: 'Esporta in formato Excel',
  },
  elencoTesti: {
    id: 'elencoTesti',
    defaultMessage: 'Elenco dei testi in ordine alfabetico',
  },
  tabellaHeaderTitolo: {
    id: 'tabellaHeaderTitolo',
    defaultMessage: 'Titolo',
  },
  tabellaHeaderAutore: {
    id: 'tabellaHeaderAutore',
    defaultMessage: 'Autore',
  },
  tabellaHeaderEdizione: {
    id: 'tabellaHeaderEdizione',
    defaultMessage: 'Edizione',
  },
  selectExcelFile: {
    id: 'selectExcelFile',
    defaultMessage: 'Seleziona file excel',
  },
  selectedFile: {
    id: 'selectedFile',
    defaultMessage: 'File selezionato:',
  },
  importSuccessful: {
    id: 'importSuccessful',
    defaultMessage: 'Il file excel è stato importato correttamente',
  },
  uploadError: {
    id: 'uploadError',
    defaultMessage: 'Si è verificato un errore nel caricamento del file',
  },
  uploadLoading: {
    id: 'uploadLoading',
    defaultMessage: 'Upload in corso...',
  },
});
