import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { UniversalLink } from '@plone/volto/components';

import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardReadMore,
} from 'design-react-kit/dist/design-react-kit';

const ResultCard = ({ searchResults }) => {
  const intl = useIntl();
  return (
    <div className="card-container row">
      {searchResults.map((item) => {
        const { title, description } = item;
        return (
          <div
            className="single-card-container col-lg-4 col-md-6 col-sm-12"
            key={item['@id']}
          >
            <Card>
              <CardBody tag="div">
                <CardTitle tag="h4">{title}</CardTitle>
                <CardText title={description}>
                  {description.slice(0, 140)}
                  {description.length > 140 ? '…' : ''}
                </CardText>
                <CardReadMore
                  tag={UniversalLink}
                  text={intl.formatMessage(messages.leggiLink)}
                  iconName="it-arrow-right"
                  href={item['@id']}
                />
              </CardBody>
            </Card>
          </div>
        );
      })}
    </div>
  );
};

export default ResultCard;

const messages = defineMessages({
  leggiLink: {
    id: 'leggiLink',
    defaultMessage: 'Leggi',
  },
});
