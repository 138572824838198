// CUSTOMIZATION
// - cambiato nome area riservata
// - azioni customizzate
// - componente UniversalLink da plone per logout
// - rimosso controllo su utenti base senza ruoli
// - aggiunta chiamata a @actions per avere actions anche su nonContentRoutes
// - aggiunta chiamata a @actions con path / se la view è notFound

/* eslint-disable react-hooks/exhaustive-deps */
/**
 * ArLogin component.
 * @module components/ItaliaTheme/Header/HeaderSlim/ArLogin
 */

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import jwtDecode from 'jwt-decode';
import {
  Row,
  Col,
  DropdownMenu,
  DropdownToggle,
  LinkList,
  LinkListItem,
  UncontrolledDropdown,
} from 'design-react-kit/dist/design-react-kit';

import { UniversalLink } from '@plone/volto/components';
import { getUser, listActions } from '@plone/volto/actions';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import { BodyClass, getBaseUrl } from '@plone/volto/helpers';

import {
  Icon,
  UserLoggedMenu,
  LoginButton,
} from 'design-comuni-plone-theme/components/ItaliaTheme';

import config from '@plone/volto/registry';

const messages = defineMessages({
  arLogin: {
    id: "Accedi all'area personale",
    defaultMessage: 'Area riservata al personale',
  },
  arLogout: {
    id: 'arLogout',
    defaultMessage: 'Esci',
  },
});

const ArLogin = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const userId = useSelector((state) =>
    state.userSession.token ? jwtDecode(state.userSession.token).sub : null,
  );

  const userLogged = useSelector((state) => state.users.user);
  const userLoggedSt = useSelector((state) => state.users);

  const userActions = useSelector(
    (state) => state.actions.actions.user_menu_actions,
  );

  const notFound = useSelector((state) => state.content.data) === null;

  useEffect(() => {
    if (!userLoggedSt?.get?.loading && userId) {
      dispatch(getUser(userId));
    }
  }, [userId]);

  useEffect(() => {
    if (
      config.settings.nonContentRoutes.some((r) => new RegExp(r).test(pathname))
    ) {
      dispatch(listActions(getBaseUrl(pathname)));
    } else if (notFound) {
      dispatch(listActions('/'));
    }
  }, [listActions, dispatch, pathname, notFound]);

  let rolesBodyClasses = [];
  // eslint-disable-next-line no-unused-expressions
  userLogged?.roles?.forEach((role) => {
    rolesBodyClasses.push(`role-${role.toLowerCase()}`);
  });

  if (!userLogged?.roles || userLogged?.roles?.length === 0) {
    rolesBodyClasses.push('no-user-roles');
  }

  return (
    <>
      {/* add user roles classes to body */}
      {rolesBodyClasses?.length > 0 && (
        <BodyClass className={rolesBodyClasses.join(' ')} />
      )}

      {config.settings.siteProperties?.arLoginUrl ? (
        <>
          {!userId ? (
            // not logged
            <LoginButton>
              <span className="rounded-icon">
                <Icon color="primary" icon="it-user" padding={false} size="" />
              </span>
              <span className="d-none d-lg-block">
                {intl.formatMessage(messages.arLogin)}
              </span>
            </LoginButton>
          ) : (
            // logged
            <>
              {/* dropdown */}
              <UncontrolledDropdown nav tag="div">
                <DropdownToggle
                  aria-haspopup
                  caret
                  color="secondary"
                  nav
                  className="btn-icon"
                >
                  <span class="rounded-icon">
                    <Icon color="primary" icon="it-user" size="" />
                  </span>
                  <span class="d-none d-lg-block">
                    {userLogged.fullname
                      ? userLogged.fullname
                      : userLogged.username}
                  </span>
                  <Icon color="" icon="it-expand" padding={false} size="" />
                </DropdownToggle>
                <DropdownMenu flip tag="div">
                  <Row tag="div">
                    <Col
                      size="12"
                      tag="div"
                      widths={['xs', 'sm', 'md', 'lg', 'xl']}
                    >
                      <LinkList tag="div">
                        <UserLoggedMenu />
                        <LinkListItem divider tag="a" />
                        {userActions?.map((action) => (
                          <LinkListItem
                            href={action.url || '/'}
                            title={action.title}
                            tag={UniversalLink}
                            className="user-menu-item"
                            target={isInternalURL(action.url) ? '' : '_blank'}
                            overrideMarkSpecialLinks={true}
                            key={action.id}
                          >
                            <Icon
                              color=""
                              icon={action.icon}
                              size="sm"
                              left
                              className="mr-2"
                            />
                            <span>{action.title}</span>
                          </LinkListItem>
                        ))}
                      </LinkList>
                    </Col>
                  </Row>
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          )}
        </>
      ) : null}
    </>
  );
};

export default ArLogin;
