import React from 'react';
import Select from 'react-select';
import { useIntl, defineMessages } from 'react-intl';

const DestinatarioWidget = ({
  name,
  title,
  label,
  description,
  required,
  onChange,
  value,
  isDisabled,
  formHasErrors,
  facets = [],
  showPlaceholder,
}) => {
  const intl = useIntl();
  const destinatari = facets.map((obj) => ({
    label: obj.name,
    value: obj.email,
  }));

  return (
    <div className="form-group mb-4">
      {showPlaceholder ? (
        <></>
      ) : (
        <label className="active field-title-label">{title}</label>
      )}
      <div className="recipient-infoText">{description}</div>
      <Select
        components={{
          IndicatorSeparator: null,
        }}
        id={name}
        name={name}
        label={title}
        infoText={description}
        isSearchable={true}
        onChange={(v) => {
          onChange(name, v.value);
        }}
        options={[
          ...(destinatari?.map((v) => ({
            value: v.value,
            label: v.label,
          })) ?? []),
        ]}
        isDisabled={isDisabled}
        placeholder={
          showPlaceholder ? title : intl.formatMessage(messages.select_a_value)
        }
        aria-label={intl.formatMessage(messages.select_a_value)}
        classNamePrefix="react-select"
        className="form-select"
      />
    </div>
  );
};

export default DestinatarioWidget;

const messages = defineMessages({
  select_a_value: {
    id: 'form_select_a_value',
    defaultMessage: 'Seleziona un valore',
  },
});
