/** CUSTOMIZATION
 *
 * - line 38-40 - cambiato classi in base a variabile showBg da Sidebar
 */

/**
 * View Accordion block.
 * @module components/ItaliaTheme/Blocks/Accordion/View
 */

import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import ViewBlock from './Block/ViewBlock';
import {
  Container,
  Card,
  CardBody,
} from 'design-react-kit/dist/design-react-kit';

/**
 * View Accordion block class.
 * @class View
 * @extends Component
 */
const AccordionView = ({ data, block }) => {
  const [itemOpen, setItemOpen] = useState(-1);
  const toggle = (index) => {
    setItemOpen(index === itemOpen ? -1 : index);
  };
  const id = new Date().getTime();
  const showBg = data.showBg;

  return (
    <div className="block accordion">
      <div className="public-ui">
        <div
          className={cx('full-width section', {
            'section-muted section-inset-shadow py-5': showBg,
            'py-0': !showBg,
          })}
        >
          <Container className="px-md-4">
            <Card
              className="card-bg rounded pt-3 pb-1"
              noWrapper={false}
              space
              tag="div"
            >
              <CardBody tag="div">
                {data.subblocks.map((subblock, index) => (
                  <ViewBlock
                    data={subblock}
                    toggle={() => {
                      return () => toggle(index);
                    }}
                    isOpen={itemOpen === index}
                    key={index}
                    id={id}
                    index={index}
                  />
                ))}
              </CardBody>
            </Card>
          </Container>
        </div>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
AccordionView.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AccordionView;
