import React from 'react';
// eslint-disable-next-line import/no-unresolved
import logo from '@package/components/Logo/logo-footer.png?width=700';

/*
 * Customization with image
 *
 * If you have a jpg/png, do like this:
 *
 * <figure className="icon">
 *  <img src={logo} alt="" />
 * </figure>
 *
 * Note the icon class.
 */

const LogoFooter = () => (
  <figure className="icon mb-0">
    <img src={logo} alt="" width="350" height="163" />
  </figure>
);

export default LogoFooter;
