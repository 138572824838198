import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import cx from 'classnames';

import {
  PageHeaderNav,
  SearchSectionForm,
  RelatedItems,
  PagePlaceholderAfterContent,
  PagePlaceholderAfterRelatedItems,
  PagePlaceholderTitle,
  TextOrBlocks,
  RelatedItemInEvidence,
  richTextHasContent,
  PageHeaderTassonomiaArgomenti,
  Sharing,
  Actions,
  PageMetadata,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

import DepartmentInfo from '@package/components/views/Common/DepartmentInfo/DepartmentInfo.jsx';

import Image from 'design-comuni-plone-theme/components/Image/Image';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
  inThisSection: {
    id: 'In this section',
    defaultMessage: 'In questa sezione',
  },
  modified: {
    id: 'modified',
    defaultMessage: 'Ultimo aggiornamento',
  },
});

const PrestazioneView = (props) => {
  const { content } = props;
  const intl = useIntl();
  const rightHeaderHasContent =
    content.image?.scales ||
    richTextHasContent(content.info_testata) ||
    content?.prestazione_department?.length > 0 ||
    content.mostra_navigazione ||
    content?.tassonomia_argomenti?.length > 0 ||
    content.mostra_bottoni_condivisione;

  return (
    <>
      <div id="prestazione-view" className="ui container px-4">
        {/*-----Testata-----*/}
        <div className="PageHeaderWrapper mb-4">
          <div className="row">
            <div
              className={cx('title-description-wrapper', {
                'col-lg-6': rightHeaderHasContent,
                'col-lg-12': !rightHeaderHasContent,
              })}
            >
              <PagePlaceholderTitle content={content}>
                <h1 className="mb-3">{content?.title}</h1>
              </PagePlaceholderTitle>

              <p className="description">{content?.description}</p>
              {content?.ricerca_in_testata && (
                <SearchSectionForm content={content} />
              )}
            </div>
            {rightHeaderHasContent && (
              <div className="col-lg-4 offset-lg-2">
                {content.mostra_bottoni_condivisione && (
                  <div className="px-4 mb-4">
                    <Sharing url={content['@id']} title={content.title} />
                    <Actions url={content['@id']} title={content.title} />
                  </div>
                )}
                {content.image?.scales && (
                  <div className="header-image px-4 mb-3">
                    <Image
                      image={content.image}
                      alt={content.title}
                      maxSize={300}
                      key={content.image?.download}
                    />
                  </div>
                )}
                <DepartmentInfo
                  headerInfo={content.info_testata}
                  departmentInfo={content.prestazione_department}
                />

                {content.mostra_navigazione && (
                  <PageHeaderNav
                    content={content}
                    title={intl.formatMessage(messages.inThisSection)}
                  />
                )}

                {content?.tassonomia_argomenti?.length > 0 && (
                  <div className="px-4">
                    <PageHeaderTassonomiaArgomenti content={content} />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <TextOrBlocks content={content} />

        {content.show_modified && <PageMetadata content={content} />}
      </div>

      <PagePlaceholderAfterContent content={content} />
      <RelatedItems content={content} />
      <RelatedItemInEvidence content={content} />
      <PagePlaceholderAfterRelatedItems content={content} />
    </>
  );
};

export default PrestazioneView;
